<template>
    <v-dialog persistent v-model="dialog" scrollable max-width="600">
        <template v-slot:activator="{ on }">
            <div style="height:24px" v-on="on" class="d-flex  align-center">
                <v-icon color="secondary" small>add</v-icon><span class="secondary--text">Assign role</span>
            </div>
        </template>
        <v-card>
            <v-card-title>
                <div class="text-uppercase primary--text">
                    <v-icon class="mr-1" color="primary">group_add</v-icon>
                    assign roles
                </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text style="height: 400px;">
                <div class="d-flex justify-space-between align-center pa-2 mt-2 mb-3 info-tile">
                    <span class="text-uppercase font-weight-bold">Assignee: {{ assignee }}</span>
                    <v-btn v-if="assignmentTab === assignmentTabOptions.SELECT_ROLES_TAB" color="primary" @click="expand = !expand" icon>
                        <v-icon>search</v-icon>
                    </v-btn>
                </div>
                <v-tabs v-model="assignmentTab">
                    <v-tab :disabled="assigningRole === true">
                        Select roles
                    </v-tab>
                    <v-tab-item>
                        <div>
                            <v-text-field v-if="expand === true" label="Search.." v-model="targetSearch"></v-text-field>
                            <v-row
                                align="center"
                                justify="center"
                                v-if="spaceName && spaceRole !== roleTypes.SPACE_ADMIN && spaceName.toLowerCase().includes(targetSearch.toLowerCase())"
                            >
                                <v-col cols="1">
                                    <v-checkbox :disabled="assigningRole" color="secondary" v-model="spaceRoleBox"></v-checkbox>
                                </v-col>
                                <v-col cols="11">
                                    <div>
                                        <span class="d-flex align-center ma-0 pl-2 mt-3 font-weight-bold"
                                            >{{ spaceName }}<v-chip class="ml-1" x-small color="secondary">space</v-chip></span
                                        >
                                        <v-radio-group :disabled="spaceRoleBox !== true || assigningRole" class="ma-0 pl-2 pb-0" v-model="spaceAdminRole" row>
                                            <v-radio>
                                                <template v-slot:label>
                                                    <span class="caption">{{ spaceRoleLabels.SPACE_ADMIN }}</span>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                    </div>
                                    <v-divider></v-divider
                                ></v-col>
                            </v-row>
                            <v-row align="center" justify="center" v-for="instance in filteredInstanceList" :key="instance.iid">
                                <v-col cols="1">
                                    <v-checkbox :disabled="assigningRole" color="secondary" v-model="instance.selectedInstance"></v-checkbox>
                                </v-col>
                                <v-col cols="11">
                                    <div>
                                        <span class="d-flex align-center ma-0 pl-2 mt-3 font-weight-bold"
                                            >{{ instance.instance_name }}<v-chip class="ml-1 caption" x-small color="primary" outlined>Instance</v-chip></span
                                        >
                                        <v-radio-group
                                            :disabled="instance.selectedInstance !== true || assigningRole"
                                            class="ma-0 pl-2 pb-0"
                                            v-model="instance.selectedRole"
                                            row
                                        >
                                            <v-radio>
                                                <template v-slot:label>
                                                    <span class="caption">{{ roleTypes.INSTANCE_EDITOR }}</span>
                                                </template>
                                            </v-radio>
                                            <v-radio>
                                                <template v-slot:label>
                                                    <span class="caption">{{ roleTypes.INSTANCE_VIEWER }}</span>
                                                </template>
                                            </v-radio>
                                        </v-radio-group>
                                    </div>
                                    <v-divider></v-divider
                                ></v-col>
                            </v-row>
                        </div>
                    </v-tab-item>
                    <v-tab :disabled="disableReviewTab === true">
                        review outcome
                    </v-tab>
                    <v-tab-item class="mt-3">
                        <v-row align="center" v-for="(outcome, index) in roleAssignmentOutcome" :key="index">
                            <v-col cols="3">
                                <v-chip class="font-weight-bold caption text-uppercase" small v-if="outcome.outcome === 'failure'" color="error"
                                    ><v-avatar left> <v-icon small>cancel</v-icon> </v-avatar>failed</v-chip
                                >
                                <v-chip class="font-weight-bold caption text-uppercase" small v-else-if="outcome.outcome === 'success'" color="success"
                                    ><v-avatar left> <v-icon small>check_circle</v-icon> </v-avatar>assigned</v-chip
                                >
                            </v-col>
                            <v-col cols="9">
                                <div class="d-flex flex-column subtitle-2 text-uppercase">
                                    <span>role: {{ outcome.role }}</span>
                                    <span>target: {{ outcome.target }}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <span v-if="assignmentTab === assignmentTabOptions.SELECT_ROLES_TAB" class="caption text-uppercase"
                    >{{ selectedRolesLength }} roles Selected</span
                >
                <v-spacer></v-spacer>
                <v-btn :disabled="assigningRole === true" color="primary" text @click="clearAndCloseDialog()">close</v-btn>
                <v-btn :loading="assigningRole" :disabled="activateAssignButton === false" color="error" text @click="assignRoles()">assign</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { enumsData } from '@/mixins/enums'

export default {
    name: 'AssignRolesDialog',
    mixins: [enumsData],
    props: {
        instanceData: Array,
        assignee: String,
        spaceName: String,
        ucid: String,
        spaceRole: String
    },
    data() {
        return {
            dialog: false,
            expand: false,
            instances: [],
            targetSearch: '',
            spaceRoleBox: false,
            spaceAdminRole: 0,
            assigningRole: false,
            spaceRoleLabels: {
                SPACE_ADMIN: 'space administrator'
            },
            spaceRoleCodes: {
                SPACE_ADMIN: 0
            },
            instanceRoleCodes: {
                INSTANCE_EDITOR: 0,
                INSTANCE_VIEWER: 1
            },
            assignmentTab: 0,
            assignmentTabOptions: {
                SELECT_ROLES_TAB: 0,
                REVIEW_AND_ASSIGN_TAB: 1
            },
            roleAssignmentOutcome: [],
            disableReviewTab: true
        }
    },
    methods: {
        assignRoles: function() {
            this.$data.disableReviewTab = false
            this.$data.assignmentTab = this.$data.assignmentTabOptions.REVIEW_AND_ASSIGN_TAB
            this.$data.assigningRole = true
            const targetInstances = this.$data.instances.filter(instance => instance.selectedInstance === true)
            if (this.$data.spaceRoleBox === true && targetInstances.length > 0) {
                this.assignSpaceRole().finally(() => {
                    this.assignInstanceRoles(targetInstances)
                })
            } else if (this.$data.spaceRoleBox === true && !targetInstances.length) {
                this.assignSpaceRole().finally(() => {
                    this.$data.assigningRole = false
                })
            } else if (this.$data.spaceRoleBox !== true && targetInstances.length > 0) {
                this.assignInstanceRoles(targetInstances)
            }
        },
        resetSelectedSpaceData: function() {
            this.$data.spaceRoleBox = null
        },
        resetSelectedInstanceData: function() {
            this.$data.instances.forEach((instance, index) => {
                const newObject = Object.assign(instance, { selectedRole: null, selectedInstance: false })
                this.$set(this.$data.instances, index, newObject)
            })
        },
        clearAndCloseDialog: function() {
            this.$data.targetSearch = ''
            this.resetSelectedInstanceData()
            this.resetSelectedSpaceData()
            this.$data.clearAndCloseDialog = []
            this.$data.roleAssignmentOutcome = []
            this.$data.dialog = false
            this.$data.assignmentTab = this.$data.assignmentTabOptions.SELECT_ROLES_TAB
            this.$data.disableReviewTab = true
        },
        assignSpaceRole: function() {
            const apiURL = `/spaces/${this.$route.params.sid}/assign_role_for_conn`
            const postBody = {
                role_name: this.roleTypes.SPACE_ADMIN,
                ucid: this.$props.ucid
            }
            return this.$axios
                .post(apiURL, postBody)
                .then(response => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Successfully assigned user role',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('spaceStore/fetchSpaceUsers', this.$route.params.sid)
                    this.$store.dispatch('instanceStore/fetchInstanceUsers', this.$route.params.iid)
                    this.$data.roleAssignmentOutcome.push({ role: this.spaceRoleLabels.SPACE_ADMIN, target: this.$props.spaceName, outcome: 'success' })
                    this.$data.spaceRoleBox = false
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'An error has occurred',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                    this.$data.roleAssignmentOutcome.push({ role: this.spaceRoleLabels.SPACE_ADMIN, target: this.$props.spaceName, outcome: 'failure' })
                })
        },
        assignInstanceRoles: function(instancesToAssign) {
            var numTargetInstances = instancesToAssign.length
            instancesToAssign.forEach(instance => {
                const apiURL = `/instances/${instance.iid}/assign_role_for_conn`
                const roleToAssign =
                    instance.selectedRole === this.instanceRoleCodes.INSTANCE_EDITOR ? this.roleTypes.INSTANCE_EDITOR : this.roleTypes.INSTANCE_VIEWER
                const postBody = {
                    role_name: roleToAssign,
                    ucid: this.$props.ucid
                }
                this.$axios
                    .post(apiURL, postBody)
                    .then(response => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: `Successfully assigned ${roleToAssign} role to the instance ${instance.instance_name}`,
                            snackBarTimeout: 5000,
                            snackBarIcon: 'check_circle'
                        })
                        this.$data.roleAssignmentOutcome.push({ role: roleToAssign, target: instance.instance_name, outcome: 'success' })
                    })
                    .catch(() => {
                        this.$store.dispatch('showSnackBar', {
                            snackBarText: `Could not assign ${roleToAssign} role to the instance ${instance.instance_name}`,
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        })
                        this.$data.roleAssignmentOutcome.push({ role: roleToAssign, target: instance.instance_name, outcome: 'failure' })
                    })
                    .finally(() => {
                        numTargetInstances -= 1
                        if (numTargetInstances === 0) {
                            this.$store.dispatch('spaceStore/fetchSpaceUsers', this.$route.params.sid)
                            this.$store.dispatch('instanceStore/fetchInstanceUsers', this.$route.params.iid)
                            this.$data.assigningRole = false
                        }
                        if (this.$data.roleAssignmentOutcome.length === this.selectedRolesLength) {
                            this.$data.assigningRole = false
                        }
                    })
            })
        }
    },
    mounted() {
        this.$data.instances = this.$props.instanceData
    },
    computed: {
        activateAssignButton() {
            const selectedInstances = this.$data.instances.filter(instance => instance.selectedInstance === true && instance.selectedRole !== null)
            if (this.$data.spaceRoleBox === true || selectedInstances.length > 0) {
                return true
            }
            return false
        },
        selectedRolesLength() {
            const selectedInstances = this.$data.instances.filter(instance => instance.selectedInstance === true)
            if (this.$data.spaceRoleBox === true) {
                return selectedInstances.length + 1
            } else {
                return selectedInstances.length
            }
        },
        filteredInstanceList() {
            if (this.$data.targetSearch.length) {
                return this.$data.instances.filter(instance => instance.instance_name.toLowerCase().includes(this.$data.targetSearch.toLowerCase()))
            } else {
                return this.$data.instances
            }
        }
    },
    watch: {
        instanceData: function(nextVal, preVal) {
            this.$data.instances = nextVal
        }
    }
}
</script>
